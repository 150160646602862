.colors {
    display: flex;
    flex-direction: row;
    flex-wrap: 1;
    margin-bottom: 1rem;
}
.swatch {
    display: flex;
    flex-direction: column;
    margin: 0 2.5rem 1em 0;
}
.chip {
    display: flex;
    flex-grow: 1;
    height: 60px;
    width: 60px;
}
.label {
    background-color: #FFF;
    font-size: 1.3rem;
    padding: .25em 0;
    @include font-mono-400;
}
.gradient1 {
  background: #F43C40; /* Old browsers */
  background: -moz-linear-gradient(180deg, #C20C16 0%, #F43C40 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, #C20C16 0%,#F43C40 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, #C20C16 0%,#F43C40 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C20C16', endColorstr='#F43C40',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.gradient2 {
  background: #00E4A0; /* Old browsers */
  background: -moz-linear-gradient(180deg, #00A675 0%, #00E4A0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, #00A675 0%,#00E4A0 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, #00A675 0%,#00E4A0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00A675', endColorstr='#00E4A0',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.gradient3 {
  background: #006DFF; /* Old browsers */
  background: -moz-linear-gradient(180deg, #0012C5 0%, #006DFF 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, #0012C5 0%,#006DFF 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, #0012C5 0%,#006DFF 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0012C5', endColorstr='#006DFF',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.product-gradient {
  background: #00adef; /* Old browsers */
  background: -moz-linear-gradient(45deg, #00adef 0%, #168f3a 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #00adef 0%,#168f3a 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #00adef 0%,#168f3a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00adef', endColorstr='#168f3a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
