html,
:root {
  font-size: 10px;
}

@import "reset";
@import "variables";
@import "fonts";

body {
  @include font-400;
  background: white;
}
#content {
    max-width: 768px;
}
article section {
  margin-bottom: 5em !important;
}
article section.noAfterSpace {
  margin-bottom: 0 !important;
}
.topSpace {
  margin-top: 1.5em;
}
article section.divide {
  border-top: 1px dotted #D6D4D2;
}
article section.additional {
  margin-bottom: 2em !important;
}
article section p:first-child {
  margin-top: 1em;
}
.addthree {
  padding-bottom: 3em !important;
}
.addfour {
  padding-bottom: 4em !important;
}
.addsix {
  padding-bottom: 6em !important;
}
.oneEmTop {
  margin-top: 1em !important;
}
article img {
  width: 100%;
  display: block;

  &.img-small {
    max-width: 200px;
    margin-left: 0;
  }
}

.input--number > input[type="number"] {
  -moz-appearance: textfield !important;
  // padding: 1.25em 0 .5em 0 !important;
}

// Theme components
@import "theme/search";
@import "theme/toc";
@import "theme/header";
@import "theme/footer";

@import "theme/color-swatch";
@import "theme/specimen";
@import "theme/icon-box";

@import "theme/text";

@import "theme/containers";
