footer {
  margin: 10em 0 2em 0;
  border-top: 2px black solid;
  padding: .75em 0 0 .25em;
}
.footlabel {
  display: flex;
  align-items: center;
}
.footlabel .text {
  margin: .135em 0 0 0;
}
