#toc {
  column-count: 3;
  padding: 1em 0;
  border-top: 1px dotted #D6D4D2;
  // border-bottom: 1px dotted #D6D4D2;
}
#toc p {
  font-family: $DIN-M-400;
  margin-left: 2px;
  margin-bottom: .25em;
}

@media (max-width: 400px) {
  #toc {
    column-count: 2;
  }
}
