header {
    margin: 6em 0;
}

.h1span {
  @include font-700;
  display: block;
  margin-top: .66em;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: .03em;
}
.introduction {
  @include font-600;
  font-size: 2.6rem;
  margin: 0 0 2em;
}
.article-introduction {
  @include font-400;
  font-size: 2.6rem;
  margin: 0 0 1em;
}
