h1 {
  @include font-cond-300;
  font-size: 3.6rem;
  margin: 0 0 0.25em;
}
h2 {
  @include font-500;
  font-size: 2.2rem;
  margin: 1.25em 0 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
h3 {
  @include font-700;
  font-size: 2.4rem;
  margin: 0 0 .90em;
}
h5 {
  @include font-700;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .03em;
  border-top: solid 1px #E1E1E1;
}
h6 {
  @include font-700;
  font-size: 1.6rem;
  margin: 0 0 .66em;
}

a,
a:link,
a:active,
a:focus,
a:hover,
a:visited {
  color: inherit;
}

code {
  // @include font-mono-400;
  font-family: $DIN-M-400;
  font-size: .9em;
  background-color: #f1f1f1;
  padding: .25em .25em 0 .25em;
  border: 0.01em solid #fff;
  border-radius: 0.25em;
}

i {
  font-style: italic;
}
b {
  @include font-700;
  font-weight: 700;
}

.hightlight {
  background: yellow;
  color: black;
}

article {
  p {
    @include font-400;
    font-size: 1.8rem;
    margin: 0 0 1em;

    &:first-child {
        margin-top: 1em;
    }
  }

  blockquote {
    margin: 1.66em 0 1.66em 0;
    padding: 0 4em 0 1em;
    box-sizing: border-box;
    font-style: italic;
    &.roman {
      font-style: normal;
    }
  }

  ul,
  ol {
    margin: 0 0 2em;

    li {
      @include font-400;
      font-size: 1.8rem;
      margin: 0 0 0.125em 1em;
    }
    &.description li {
      list-style: none;
      list-style-type: none;
      margin-left: 0;
      margin-bottom: .5em;

      b {
        @include font-600;
      }
    }
  }
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
}

.spcd-01 {
  letter-spacing: 0.01em;
}
.spcd-02 {
  letter-spacing: 0.02em;
}
.spcd-03 {
  letter-spacing: 0.03em;
}
.spcd-04 {
  letter-spacing: 0.04em;
}
.spcd-05 {
  letter-spacing: 0.05em;
}
.spcd-06 {
  letter-spacing: 0.06em;
}
.wip {color:#00e4a0;}
