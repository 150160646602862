.icon-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 1em;

  >* {
    margin: 1.5rem;
    width: 3rem;
    flex-shrink: 0;
  }
}
