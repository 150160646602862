%margin-sides {
  margin-left: auto;
  margin-right: auto;
}
%margin-top-bottom {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.container-1024 {
  @extend %margin-sides;
  @extend %margin-top-bottom;
  max-width: 1024px;
}
.container-808 {
  @extend %margin-sides;
  @extend %margin-top-bottom;
  max-width: 808px;
}

.container-full {
  @extend %margin-top-bottom;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
