#search {
  margin-top: 2rem;

  input, .tag {
    margin: 1rem 1rem 0 0;
  }

  input {
    width: auto;
    flex-grow: 1;
    width: 80px;

    padding: 0;

    font-size: 2em;
    // RESET
    appearance: none;
    border: none;
    background: none;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
    // END
  }

  .tag {
    flex-shrink: 0;

    font-size: 1.25em;
    padding: .5em;
    border-radius: .2em;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    max-width: calc(100% - .5rem);

    .icon {
      height: .75em;
      margin-left: 1em;
      flex-shrink: 0;
      cursor: pointer;
    }
    .text {
      line-height: 1;
      font-size: 1.2em;
      color: gray;
    }
  }

  .searchbox {
    display: flex;
    flex-wrap: wrap;
    min-height: 52.5px;
    box-sizing: border-box;

    border: 2px solid lightgray;
    border-radius: .5em;

    padding: 0 3.66rem 1rem 1rem;
    position: relative;

    .tag {
      background-color: black;
      .icon svg path {
        fill: white;
      }
      .text {
        color: white;
      }
    }

    > .search-icon {
      position: absolute;
      right: 0;
      top: 1.6rem;
      margin-right: 1.3em;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    input {
      font-family: "DIN-Next-Regular";
      padding-top: .23em;
    }
  }

  .tags {
    display: flex;
    margin-left: .5em;
    .tag {
      cursor: pointer;
      .icon {
        display: none;
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
