$DIN-200: "DIN-Next-Light"; // NOTE
$DIN-300: "DIN-Next-Light";
$DIN-400: "DIN-Next-Regular";
$DIN-500: "DIN-Next-Medium";
$DIN-600: "DIN-Next-Medium"; // NOTE
$DIN-700: "DIN-Next-Bold";
$DIN-800: "DIN-Next-Bold"; // NOTE
$DIN-900: "DIN-Next-Bold"; // NOTE

$DIN-200-it: "#{$DIN-200}-Italic";
$DIN-300-it: "#{$DIN-300}-Italic";
$DIN-400-it: "#{$DIN-400}-Italic";
$DIN-500-it: "#{$DIN-500}-Italic";
$DIN-600-it: "#{$DIN-600}-Italic";
$DIN-700-it: "#{$DIN-700}-Italic";
$DIN-800-it: "#{$DIN-800}-Italic";
$DIN-900-it: "#{$DIN-900}-Italic";

@font-face {
  font-family: $DIN-200;
  src: url("../fonts/#{$DIN-200}.ttf") format("ttf"),
  url("../fonts/#{$DIN-200}.eot") format("eot"),
  url("../fonts/#{$DIN-200}.woff") format("woff"),
  url("../fonts/#{$DIN-200}.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: $DIN-200;
  src: url("../fonts/#{$DIN-200-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-200-it}.eot") format("eot"),
  url("../fonts/#{$DIN-200-it}.woff") format("woff"),
  url("../fonts/#{$DIN-200-it}.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: $DIN-300;
  src: url("../fonts/#{$DIN-300}.ttf") format("ttf"),
  url("../fonts/#{$DIN-300}.eot") format("eot"),
  url("../fonts/#{$DIN-300}.woff") format("woff"),
  url("../fonts/#{$DIN-300}.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: $DIN-300;
  src: url("../fonts/#{$DIN-300-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-300-it}.eot") format("eot"),
  url("../fonts/#{$DIN-300-it}.woff") format("woff"),
  url("../fonts/#{$DIN-300-it}.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: $DIN-400;
  src: url("../fonts/#{$DIN-400}.ttf") format("ttf"),
  url("../fonts/#{$DIN-400}.eot") format("eot"),
  url("../fonts/#{$DIN-400}.woff") format("woff"),
  url("../fonts/#{$DIN-400}.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: $DIN-400-it;
  src: url("../fonts/#{$DIN-400-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-400-it}.eot") format("eot"),
  url("../fonts/#{$DIN-400-it}.woff") format("woff"),
  url("../fonts/#{$DIN-400-it}.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: $DIN-500;
  src: url("../fonts/#{$DIN-500}.ttf") format("ttf"),
  url("../fonts/#{$DIN-500}.eot") format("eot"),
  url("../fonts/#{$DIN-500}.woff") format("woff"),
  url("../fonts/#{$DIN-500}.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: $DIN-500;
  src: url("../fonts/#{$DIN-500-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-500-it}.eot") format("eot"),
  url("../fonts/#{$DIN-500-it}.woff") format("woff"),
  url("../fonts/#{$DIN-500-it}.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: $DIN-600;
  src: url("../fonts/#{$DIN-600}.ttf") format("ttf"),
  url("../fonts/#{$DIN-600}.eot") format("eot"),
  url("../fonts/#{$DIN-600}.woff") format("woff"),
  url("../fonts/#{$DIN-600}.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: $DIN-600;
  src: url("../fonts/#{$DIN-600-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-600-it}.eot") format("eot"),
  url("../fonts/#{$DIN-600-it}.woff") format("woff"),
  url("../fonts/#{$DIN-600-it}.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: $DIN-700;
  src: url("../fonts/#{$DIN-700}.ttf") format("ttf"),
  url("../fonts/#{$DIN-700}.eot") format("eot"),
  url("../fonts/#{$DIN-700}.woff") format("woff"),
  url("../fonts/#{$DIN-700}.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: $DIN-700;
  src: url("../fonts/#{$DIN-700-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-700-it}.eot") format("eot"),
  url("../fonts/#{$DIN-700-it}.woff") format("woff"),
  url("../fonts/#{$DIN-700-it}.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: $DIN-800;
  src: url("../fonts/#{$DIN-800}.ttf") format("ttf"),
  url("../fonts/#{$DIN-800}.eot") format("eot"),
  url("../fonts/#{$DIN-800}.woff") format("woff"),
  url("../fonts/#{$DIN-800}.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: $DIN-800;
  src: url("../fonts/#{$DIN-800-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-800-it}.eot") format("eot"),
  url("../fonts/#{$DIN-800-it}.woff") format("woff"),
  url("../fonts/#{$DIN-800-it}.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: $DIN-900;
  src: url("../fonts/#{$DIN-900}.ttf") format("ttf"),
  url("../fonts/#{$DIN-900}.eot") format("eot"),
  url("../fonts/#{$DIN-900}.woff") format("woff"),
  url("../fonts/#{$DIN-900}.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: $DIN-900;
  src: url("../fonts/#{$DIN-900-it}.ttf") format("ttf"),
  url("../fonts/#{$DIN-900-it}.eot") format("eot"),
  url("../fonts/#{$DIN-900-it}.woff") format("woff"),
  url("../fonts/#{$DIN-900-it}.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

// Set backup font stack for normal font
$font-stack: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;

@mixin font-200 {
  font-family: $DIN-200, $font-stack;
  font-weight: 200;
}
@mixin font-300 {
  font-family: $DIN-300, $font-stack;
  font-weight: 300;
}
@mixin font-400 {
  font-family: $DIN-400, $font-stack;
  font-weight: 400;
}
@mixin font-500 {
  font-family: $DIN-500, $font-stack;
  font-weight: 500;
}
@mixin font-600 {
  font-family: $DIN-600, $font-stack;
  font-weight: 600;
}
@mixin font-700 {
  font-family: $DIN-700, $font-stack;
  font-weight: 700;
}
@mixin font-800 {
  font-family: $DIN-800, $font-stack;
  font-weight: 800;
}
@mixin font-900 {
  font-family: $DIN-900, $font-stack;
  font-weight: 900;
}

.font-200 {
  @include font-200;
}
.font-300 {
  @include font-300;
}
.font-400 {
  @include font-400;
}
.font-500 {
  @include font-500;
}
.font-600 {
  @include font-600;
}
.font-700 {
  @include font-700;
}
.font-800 {
  @include font-800;
}
.font-900 {
  @include font-900;
}

$DIN-C-200: "DIN-Next-Condensed-Ultra-Light";
$DIN-C-300: "DIN-Next-Condensed-Light";
$DIN-C-400: "DIN-Next-Condensed-Light"; // NOTE
$DIN-C-500: "DIN-Next-Condensed-Light"; // NOTE
$DIN-C-600: "DIN-Next-Condensed-Light"; // NOTE
$DIN-C-700: "DIN-Next-Condensed-Light"; // NOTE
$DIN-C-800: "DIN-Next-Condensed-Light"; // NOTE
$DIN-C-900: "DIN-Next-Condensed-Light"; // NOTE

@font-face {
  font-family: $DIN-C-200;
  src: url("../fonts/#{$DIN-C-200}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-200}.eot") format("eot"),
  url("../fonts/#{$DIN-C-200}.woff") format("woff"),
  url("../fonts/#{$DIN-C-200}.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-300;
  src: url("../fonts/#{$DIN-C-300}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-300}.eot") format("eot"),
  url("../fonts/#{$DIN-C-300}.woff") format("woff"),
  url("../fonts/#{$DIN-C-300}.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-400;
  src: url("../fonts/#{$DIN-C-400}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-400}.eot") format("eot"),
  url("../fonts/#{$DIN-C-400}.woff") format("woff"),
  url("../fonts/#{$DIN-C-400}.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-500;
  src: url("../fonts/#{$DIN-C-400}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-400}.eot") format("eot"),
  url("../fonts/#{$DIN-C-400}.woff") format("woff"),
  url("../fonts/#{$DIN-C-400}.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-600;
  src: url("../fonts/#{$DIN-C-600}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-600}.eot") format("eot"),
  url("../fonts/#{$DIN-C-600}.woff") format("woff"),
  url("../fonts/#{$DIN-C-600}.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-700;
  src: url("../fonts/#{$DIN-C-700}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-700}.eot") format("eot"),
  url("../fonts/#{$DIN-C-700}.woff") format("woff"),
  url("../fonts/#{$DIN-C-700}.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-800;
  src: url("../fonts/#{$DIN-C-800}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-800}.eot") format("eot"),
  url("../fonts/#{$DIN-C-800}.woff") format("woff"),
  url("../fonts/#{$DIN-C-800}.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: $DIN-C-900;
  src: url("../fonts/#{$DIN-C-900}.ttf") format("ttf"),
  url("../fonts/#{$DIN-C-900}.eot") format("eot"),
  url("../fonts/#{$DIN-C-900}.woff") format("woff"),
  url("../fonts/#{$DIN-C-900}.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

// Set backup font stack for monospace font
$font-cond-stack: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;

@mixin font-cond-200 {
  font-family: $DIN-C-200, $font-cond-stack;
  font-weight: 200;
}
@mixin font-cond-300 {
  font-family: $DIN-C-300, $font-cond-stack;
  font-weight: 300;
}
@mixin font-cond-400 {
  font-family: $DIN-C-400, $font-cond-stack;
  font-weight: 400;
}
@mixin font-cond-500 {
  font-family: $DIN-C-500, $font-cond-stack;
  font-weight: 500;
}
@mixin font-cond-600 {
  font-family: $DIN-C-600, $font-cond-stack;
  font-weight: 600;
}
@mixin font-cond-700 {
  font-family: $DIN-C-700, $font-cond-stack;
  font-weight: 700;
}
@mixin font-cond-800 {
  font-family: $DIN-C-800, $font-cond-stack;
  font-weight: 800;
}
@mixin font-cond-900 {
  font-family: $DIN-C-900, $font-cond-stack;
  font-weight: 900;
}

.font-cond-200 {
  @include font-cond-200;
}
.font-cond-300 {
  @include font-cond-300;
}
.font-cond-400 {
  @include font-cond-400;
}
.font-cond-500 {
  @include font-cond-500;
}
.font-cond-600 {
  @include font-cond-600;
}
.font-cond-700 {
  @include font-cond-700;
}
.font-cond-800 {
  @include font-cond-800;
}
.font-cond-900 {
  @include font-cond-900;
}

$DIN-M-200: "DIN-Mono-Regular"; // NOTE
$DIN-M-300: "DIN-Mono-Regular"; // NOTE
$DIN-M-400: "DIN-Mono-Regular";
$DIN-M-500: "DIN-Mono-Regular"; // NOTE
$DIN-M-600: "DIN-Mono-Regular"; // NOTE
$DIN-M-700: "DIN-Mono-Regular"; // NOTE
$DIN-M-800: "DIN-Mono-Regular"; // NOTE
$DIN-M-900: "DIN-Mono-Regular"; // NOTE

@font-face {
  font-family: $DIN-M-400;
  src: url("../fonts/#{$DIN-M-400}.eot") format("eot"),
  url("../fonts/#{$DIN-M-400}.woff") format("woff"),
  url("../fonts/#{$DIN-M-400}.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

// Set backup font stack for monospace font
$font-mono-stack: monospace;

@mixin font-mono-200 {
  font-family: $DIN-M-200, $font-mono-stack;
  font-weight: 200;
}
@mixin font-mono-300 {
  font-family: $DIN-M-300, $font-mono-stack;
  font-weight: 300;
}
@mixin font-mono-400 {
  font-family: $DIN-M-400, $font-mono-stack;
  font-weight: 400;
}
@mixin font-mono-500 {
  font-family: $DIN-M-500, $font-mono-stack;
  font-weight: 500;
}
@mixin font-mono-600 {
  font-family: $DIN-M-600, $font-mono-stack;
  font-weight: 600;
}
@mixin font-mono-700 {
  font-family: $DIN-M-700, $font-mono-stack;
  font-weight: 700;
}
@mixin font-mono-800 {
  font-family: $DIN-M-800, $font-mono-stack;
  font-weight: 800;
}
@mixin font-mono-900 {
  font-family: $DIN-M-900, $font-mono-stack;
  font-weight: 900;
}

.font-mono-200 {
  @include font-mono-200;
}
.font-mono-300 {
  @include font-mono-300;
}
.font-mono-400 {
  @include font-mono-400;
}
.font-mono-500 {
  @include font-mono-500;
}
.font-mono-600 {
  @include font-mono-600;
}
.font-mono-700 {
  @include font-mono-700;
}
.font-mono-800 {
  @include font-mono-800;
}
.font-mono-900 {
  @include font-mono-900;
}
